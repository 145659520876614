
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
$phone: 600px;

.not_found {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;

  // pi creature
  & > *:first-child {
    margin: 0;
  }

  @media (max-width: $phone) {
    flex-direction: column;
    text-align: center;
  }
}

.text {
  & > * {
    margin: 10px 0;

    &:first-child {
      font-weight: $semi-bold;
    }
  }
}
